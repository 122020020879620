import React from "react";

const Fishing = () => {
  return (
    <>
      <div class="section section-contents section-products single-product section-pad">
        <div class="container">
          <div class="content row">
            <div class="products-details row">
              <div class="col-md-8 res-m-bttm">
                <h1>Fishing Operations/Whipstock Services</h1>
                <p>
                  When it comes to fishing operations, we understand the
                  criticality of retrieving lost or stuck tools, casing, or
                  other foreign objects from your wellbore. Our team of skilled
                  professionals is equipped with the latest tools and techniques
                  to tackle the most complex fishing challenges. With our
                  extensive experience and commitment to safety, we ensure
                  efficient and cost-effective solutions to minimize downtime
                  and maximize productivity.
                </p>
                <p>
                  Together with our Offshore Technical Partners, who are world
                  class renown manufacturers of Whipstocks, Mills (Tri-mills and
                  Bi-Mills), Anchors and Packer Assembly, we carry out all forms
                  of remedial services which include;
                </p>
                <p class="gaps size-xs"></p>
                <p>
                  <img alt="" src="image/fishing/1.jpg" />
                </p>

                <h3>Products Features</h3>
                <p>Our fishing services include:</p>
                <ul class="list-style">
                  <li>
                    <strong>Tool Recovery:</strong> We specialize in recovering
                    lost tools and equipment from your wellbore, minimizing the
                    need for costly interventions. Our experts employ advanced
                    fishing tools and methodologies to efficiently retrieve the
                    equipment, ensuring minimal impact on your operations.
                  </li>
                  <li>
                    <strong>Casing Exit Systems:</strong> Our casing exit
                    systems allow you to sidetrack or exit from existing casing
                    strings, enabling access to new reserves or bypassing
                    damaged sections. We provide reliable solutions tailored to
                    your specific wellbore conditions, ensuring precise and
                    accurate placement.
                  </li>
                  <li>
                    <strong>Junk Retrieval:</strong> We excel in removing
                    unwanted debris or junk from your wellbore, including
                    drilling cuttings, cement, scale, or other materials that
                    hinder optimal production. Our team employs a range of
                    techniques, including mechanical and chemical methods, to
                    effectively clean out your well and restore its performance.
                  </li>
                </ul>
                <h3>Whipstock Services:</h3>
                <p>
                  When it comes to wellbore trajectory control and wellbore
                  placement, our whipstock services deliver exceptional results.
                  Whether you need to sidetrack a well or drill a window in the
                  casing, our specialized tools and experienced personnel ensure
                  accurate and efficient operations.
                </p>
                <p>Our whipstock services include:</p>
                <ul class="list-style">
                  <li>
                    <strong>Sidetracking Solutions</strong>Our experts design
                    and deploy whipstocks to initiate sidetracks from existing
                    wellbores. By using precise orientation and reliable
                    anchoring techniques, we create windows in the casing and
                    guide the drilling assembly to access new reservoirs or
                    bypass obstructions.
                  </li>
                  <li>
                    <strong>Window Milling: </strong>Our advanced window milling
                    services allow for the creation of windows in casing strings
                    to facilitate further drilling operations. We utilize
                    state-of-the-art milling tools and technologies to deliver
                    clean and precise cuts, ensuring optimal wellbore access and
                    successful operations.
                  </li>
                  <li>
                    <strong>Re-entry Guides: </strong>Our re-entry guides
                    provide the necessary guidance for accurate re-entry into an
                    existing wellbore. These guides offer a reliable reference
                    point, enabling precise wellbore intersection and ensuring
                    efficient operations during drilling, completion, or
                    workover activities.
                  </li>
                </ul>
                <p>
                  At E&R Global Resources, we pride ourselves on our technical
                  expertise, commitment to safety, and customer satisfaction. We
                  understand the unique challenges of the oil and gas industry
                  and strive to deliver tailored solutions that meet your
                  specific requirements. Partner with us for all your fishing
                  operations and whipstock service needs, and let us help you
                  maximize your operational efficiency and success.
                </p>

                <div class="feature-imagebox border download-action">
                  <div class="block">
                    <h3>Contact Us Today</h3>
                    <p>Reach out to us let’s discuss your application needs</p>
                    <a href="#" target="_blank" class="btn">
                      Contact Us <i class="fa fa-phone"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box boxed">
                    <h3 class="wgs-heading">Featured Products</h3>
                    <div class="wgs-content">
                      <p>
                        <img src="image/fishing/2.jpg" alt="" />
                      </p>
                     
                      
                    </div>
                  </div>

                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fishing;
