import React from "react";

const Bridge = () => {
  return (
    <>
      <div class="section section-contents section-products single-product section-pad">
        <div class="container">
          <div class="content row">
            <div class="products-details row">
              <div class="col-md-8 res-m-bttm">
                <h1>Bridge Plugs, Cement Retainers & Packers</h1>
                <p>
                  We are the OEM representative in Nigeria and the Sub Sahara
                  Africa market for Drill Bits, Drilling Optimization Tools and
                  Reamers. Be it standard PDC Bits (Steel or Matrix Body),
                  Roller Cone bits (Milled Tooth or Tungsten Carbide Inserts
                  (TCI), Bi-center Bits , Staged Hole Openers; we are a one stop
                  centre for these tools. We also offer the repairs of PDC bits
                  at a pocket friendly cost.
                </p>
                <p>
                  Our range of Drilling Optimization Tools offerings include but
                  not limited to Runners, Drivers and Extremers which are
                  vibration and shock mitigation tools. We also supply custom
                  made reamers
                </p>
                <p class="gaps size-xs"></p>
                <p>
                  <img alt="" src="image/bridgeplugs.png" />
                </p>
                <p>
                  Our drill bits are engineered from premium materials such as
                  high-speed steel (HSS), cobalt, and carbide, ensuring they can
                  handle a wide range of materials with ease. From wood and
                  metal to concrete and masonry, our drill bits are designed to
                  deliver clean, accurate holes every time. With different
                  styles and sizes available, you'll find the perfect bit for
                  any project.
                </p>
                <h3>Products Features</h3>
                <p>Discover the Features that Set Our Drill Bits Apart:</p>
                <ul class="list-style">
                  <li>
                    <strong>Superior Materials:</strong> We understand the
                    importance of durability and performance. That's why our
                    drill bits are meticulously crafted from high-quality
                    materials such as high-speed steel (HSS), cobalt, and
                    carbide. These premium materials ensure longevity and
                    enhance drilling efficiency across various materials,
                    including wood, metal, concrete, and more.
                  </li>
                  <li>
                    <strong>Precision Engineering:</strong> Our drill bits are
                    engineered with precision and accuracy in mind. Each bit is
                    designed to create clean, precise holes without any wobbling
                    or wandering. This ensures that your projects are completed
                    flawlessly, saving you time and effort.
                  </li>
                  <li>
                    <strong>Versatility</strong> Our extensive range of drill
                    bits caters to a wide range of applications. Whether you
                    need to drill through wood, metal, or concrete, we have the
                    perfect bit for your specific needs. From standard twist
                    bits to specialized spade bits, hole saws, and masonry bits,
                    our selection covers all your drilling requirements.
                  </li>
                </ul>
                <p>
                  We prioritize quality and reliability, partnering with trusted
                  manufacturers to bring you drill bits that are built to last.
                  Our team of experts is always available to provide guidance
                  and assist you in choosing the right drill bits for your
                  specific application. Explore our extensive collection today
                  and experience the difference that top-quality drill bits can
                  make in your projects.
                </p>

                <div class="feature-imagebox border download-action">
                  <div class="block">
                    <h3>Contact Us Today</h3>
                    <p>Reach out to us let’s discuss your application needs</p>
                    <a href="#" target="_blank" class="btn">
                      Contact Us <i class="fa fa-phone"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box boxed">
                    <h3 class="wgs-heading">Featured Products</h3>
                    <div class="wgs-content">
                      <p>
                        <img src="image/drillbits/drilltools.png" alt="" />
                      </p>
                     
                      
                    </div>
                  </div>

                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bridge;
