import React from "react";

const About = () => {
  return (
    <>
      <div class="section section-about pt-15">
        <div class="container">
          <div class="content row">
            <div class="row row-vm">
              <div class="col-md-6 res-m-bttm align-center">
                <h2>
                  We are team of the professionals who will build everything for
                  you
                </h2>
                <p>
                  E&R Global Resources Ltd is a young and dynamic Oil and Gas
                  Service company with interest in the Upstream Sector of the
                  industry. The focus of its set up was borne out of the need to
                  form strategic international partnerships to offer many
                  specialized services in Oil/Gas upstream space in one place.
                  We identify, attract, train and retain indigenous human
                  manpower for knowledge transfer in the Oil and Gas Sector.
                </p>
                <p>
                  E&R Global Resources Ltd is proudly 100% Nigerian content
                  registered company, duly incorporated with the Corporate
                  Affairs Commission (CAC) with registration number; RC 858975
                  in the year 2009 under the COMPANIES AND ALLIED MATTERS ACT
                  1990 of the Federal Republic of Nigeria.
                </p>
              </div>
              <div class="col-md-5 col-md-offset-1">
                <img src="image/man.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
