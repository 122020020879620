import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";

import Footer from "./Components/Footer/Footer";
import Routing from "./Routes";
import ScrollToTop from "./Scrolltotop";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "./Components/Loader/Loader"

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    // Simulate a delay or wait for data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // for example, 1 second

    return () => clearTimeout(timer);
  }, [location]);
  return (
    <>
    {loading && <Loader />}
      <Header />
      <Routing />
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default App;
