import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./Pages/AboutPage";
import Contact from "./Pages/Contact";
import Home from './Pages/Home'
import Partners from "./Pages/Partners";
import ProductsPage from "./Pages/ProductsPage";
import Drillbits from './Pages/Drillbits'
import Fishing from "./Pages/Fishing";
import Cement from "./Pages/Cement";
import Casing from "./Pages/Casing";
import Float from "./Pages/Float";
import Stage from "./Pages/Stage";
import Bridge from "./Pages/Bridge";

const Routing = () => {
  return (
    <>
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/products-and-services" element={<ProductsPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/partners" element={<Partners />} />
        <Route path='/drill-bits-drilling-optimization-tools-and-reamers' element={<Drillbits/>}/>
        <Route path='/fishing-operations-and-whipstock-services' element={<Fishing/>}/>
        <Route path='/casing-cementing-and-liner-hanger-services' element={<Cement/>}/>
        <Route path='/casing-centralizers-and-stop-collars' element={<Casing/>}/>
        <Route path='/float-shoe-and-float-collars' element={<Float/>}/>
        <Route path='/stage-cementing-tools' element={<Stage/>}/>
        <Route path='/bridge-plugs-cement-retainers-and-packers' element={<Bridge/>}/>
     </Routes>
    </>
  );
};

export default Routing;
