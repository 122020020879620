import React from "react";
import { Link } from "react-router-dom";


const Hero = () => {
  return (
    <div>
      <div
        id="slider"
        class="banner banner-slider carousel slide carousel-fade"
      >
        <div class="carousel-inner">
          <div class="item active">
            <div
              class="fill"
              style={{ "background-image": "url('image/slider-oil-a.jpg')" }}
            >
              <div class="banner-content">
                <div class="container">
                  <div class="row">
                    <div class="banner-text al-left pos-left dark">
                      <div class="animated fadeInRight">
                        <h2 class="ucap">
                          Serving the Upstream Needs In The Oil And Gas Sector
                        </h2>
                        <p>
                          We’re proud to solve upstream issues in the oil and
                          gas sector globally.
                        </p>
                        <div class="banner-cta">
                          <Link class="btn" to="/about">
                            Learn More
                          </Link>
                          <Link
                            class="btn btn-alt btn-outline"
                            to="/contact"
                          >
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div
              class="fill"
              style={{
                "background-image": "url('image/slider-welding-b.jpg')",
              }}
            >
              <div class="banner-content">
                <div class="container">
                  <div class="row">
                    <div class="banner-text al-left pos-left light">
                      <div class="animated fadeInRight">
                      <h2 class="ucap">
                          Serving the Upstream Needs In The Oil And Gas Sector
                        </h2>
                        <p>
                          We’re proud to solve upstream issues in the oil and
                          gas sector globally.
                        </p>
                        <div class="banner-cta">
                          <Link class="btn" to="/about">
                            Learn More
                          </Link>
                          <Link
                            class="btn btn-light btn-outline"
                            to="/contact"
                          >
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div
              class="fill"
              style={{ "background-image": "url('image/slider-mine-a.jpg')" }}
            >
              <div class="banner-content">
                <div class="container">
                  <div class="row">
                    <div class="banner-text al-left pos-left light">
                      <div class="animated fadeInRight">
                      <h2 class="ucap">
                          Serving the Upstream Needs In The Oil And Gas Sector
                        </h2>
                        <p>
                          We’re proud to solve upstream issues in the oil and
                          gas sector globally.
                        </p>
                        <div class="banner-cta">
                          <Link class="btn" to="/about">
                            Learn More
                          </Link>
                          <Link
                            class="btn btn-light btn-outline"
                            to="/contact"
                          >
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          class="left carousel-control"
          to="#slider"
          role="button"
          data-slide="prev"
        >
          <span
            class="glyphicon glyphicon-chevron-left"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Previous</span>
        </Link>
        <Link
          class="right carousel-control"
          to="#slider"
          role="button"
          data-slide="next"
        >
          <span
            class="glyphicon glyphicon-chevron-right"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Next</span>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
