import React from 'react'

const Offered = () => {
  return (
    <>
      
	<div class="call-action has-parallax" style={{"background-image": "url('image/plx-dark-oil.jpg')"}}>
		<div class="cta-block">
			<div class="container">
			
				<div class="content row"> 
					<h3>We offers a wide range of services including:</h3>
					<div class="gaps"></div>
					<div class="row">
						<div class="col-md-4 res-m-bttm-xs">
							<ul class="list-style">
								<li>Drill Bits, Vibration Mitigation tools, Reamers</li>
								<li>Casing, Cementing & Liner Hanger Service</li>
								<li>Drilling & Production Chemicals</li>
								<li>OCTG/Pipes & Machine Shop Services</li>
							</ul>
						</div>
						<div class="col-md-4 res-m-bttm-xs">
							<ul class="list-style">
								<li>Fishing Operations/Whipstock Services</li>
								<li>Consultancy Services & Material Supply</li>
								<li>Drill Bits, Drilling Optimization Tools & Reamers</li>
								<li>Fishing Operations & Whipstock Services</li>
							</ul>
						</div>
						<div class="col-md-4">
							<ul class="list-style">
								<li>Casing, Cementing and Liner Hanger Services</li>
								<li>Casing Centralizers and Stop Collars</li>
								<li>Float Shoe and Float Collars</li>
								<li>And More...</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </>
  )
}

export default Offered
