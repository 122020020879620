import React from "react";

const Cement = () => {
  return (
    <>
      <div class="section section-contents section-products single-product section-pad">
        <div class="container">
          <div class="content row">
            <div class="products-details row">
              <div class="col-md-8 res-m-bttm">
                <h1>Cementing and Casing Accessories</h1>
                <p>
                  Our casing services provide the essential support and
                  protection for wellbore integrity. We specialize in casing
                  installation, including running and cementing, to ensure a
                  secure and stable wellbore. Our team of highly skilled
                  professionals meticulously plans and executes casing
                  operations, taking into account wellbore specifications,
                  environmental factors, and regulatory requirements.
                </p>
                <h3>Cementing Services:</h3>
                <p>
                  Proper cementing is crucial for well integrity, zonal
                  isolation, and optimal well performance. At E&R Global
                  Resources, we offer comprehensive cementing services that
                  encompass cement design, mixing, pumping, and evaluation. Our
                  team utilizes advanced technologies and premium cementing
                  materials to deliver superior results, preventing gas
                  migration, controlling formation pressures, and providing
                  long-term wellbore stability.
                </p>
                <p class="gaps size-xs"></p>
                <p>
                  <img alt="" src="image/cement/2.png" />
                </p>
                <h3>Liner Hanger Services:</h3>
                <p>
                  Liner hangers are vital components in completing and isolating
                  sections of the wellbore. Our liner hanger services include
                  the installation, setting, and securing of liner hangers,
                  enabling efficient wellbore access and maintaining well
                  integrity. We offer a wide range of liner hanger solutions
                  tailored to your specific well requirements, ensuring proper
                  support and isolation in various drilling and completion
                  scenarios.
                </p>
                <h3>Why Choose Us:</h3>
                <ul class="list-style">
                  <li>
                    <strong>Expertise and Experience</strong> With years of
                    industry experience, our team possesses deep knowledge and
                    practical expertise in casing, cementing, and liner hanger
                    services. We bring this wealth of knowledge to every
                    project, ensuring precision and reliability in our
                    operations.
                  </li>
                  <li>
                    <strong>Cutting-edge Technology: </strong> We stay at the
                    forefront of technological advancements in the oil and gas
                    sector. By utilizing state-of-the-art equipment and
                    innovative solutions, we enhance operational efficiency and
                    deliver exceptional results.
                  </li>
                  <li>
                    <strong>Safety and Compliance:</strong> Safety is our utmost
                    priority. We adhere to strict safety protocols and industry
                    regulations to create a secure work environment for our team
                    members and clients alike. Our commitment to safety ensures
                    minimal risk and maximum protection throughout the project
                    lifecycle.
                  </li>
                  <li>
                    <strong>Customized Solutions: </strong> We understand that
                    each well presents unique challenges. Our team works closely
                    with you to develop customized solutions that address your
                    specific requirements, optimizing performance and minimizing
                    costs.
                  </li>
                  <li>
                    <strong>Timely Execution:</strong> We value your time and
                    aim for prompt project execution without compromising on
                    quality. Our well-coordinated teams and streamlined
                    processes enable us to meet project deadlines and deliver
                    results within the agreed-upon timeframe.
                  </li>
                </ul>

                <div class="feature-imagebox border download-action">
                  <div class="block">
                    <h3>Contact Us Today</h3>
                    <p>Reach out to us let’s discuss your application needs</p>
                    <a href="#" target="_blank" class="btn">
                      Contact Us <i class="fa fa-phone"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box boxed">
                    <h3 class="wgs-heading">Featured Products</h3>
                    <div class="wgs-content">
                      <p>
                        <img src="image/cement/1.png" alt="" />
                      </p>
                     
                      
                    </div>
                  </div>

                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cement;
