import React from "react";

const Casing = () => {
  return (
    <>
      <div class="section section-contents section-products single-product section-pad">
        <div class="container">
          <div class="content row">
            <div class="products-details row">
              <div class="col-md-8 res-m-bttm">
                <h1>Casing, Cementing and Liner Hanger Services</h1>
                <p>
                  Casing centralizers are essential tools used during casing
                  installation to maintain the desired standoff between the
                  casing string and the wellbore wall. These devices help
                  achieve even cement distribution, zonal isolation, and
                  effective mud displacement. By centering the casing within the
                  wellbore, centralizers mitigate the risk of differential
                  sticking, poor cement jobs, and casing wear. Our casing
                  centralizers are engineered with precision to match specific
                  well conditions, including well deviation, formation
                  properties, and casing weight.
                </p>
                <h3>Stop Collars:</h3>
                <p>
                  Stop collars are mechanical devices placed around the casing
                  string to prevent its downward or upward movement within the
                  wellbore. These collars provide axial support and prevent
                  potential casing movement during cementing and subsequent well
                  operations. By ensuring proper positioning of the casing, stop
                  collars contribute to a secure wellbore and help maintain
                  zonal isolation. We offer a wide selection of stop collars
                  designed to fit various casing sizes and configurations,
                  allowing for a reliable and customized solution for your well.
                </p>
                <p class="gaps size-xs"></p>
                <p>
                  <img alt="" src="image/collar.png" />
                </p>

                <h3>Why Choose Our Casing Accessories:</h3>
                <ul class="list-style">
                  <li>
                    <strong>Quality and Reliability: </strong> Our casing
                    centralizers and stop collars are manufactured to the
                    highest industry standards using premium materials. They
                    undergo rigorous quality checks to ensure durability,
                    reliability, and long-lasting performance in demanding well
                    environments.
                  </li>
                  <li>
                    <strong>Engineered Solutions:</strong> We understand that
                    each well has unique characteristics and challenges. Our
                    team of experts works closely with you to assess your well
                    conditions and recommend the most suitable casing
                    accessories, ensuring optimal wellbore integrity and
                    cementing efficiency.
                  </li>
                  <li>
                    <strong>Enhanced Well Performance:</strong> By using our
                    casing centralizers and stop collars, you can achieve
                    improved cement placement, reduced annular pressure,
                    enhanced zonal isolation, and increased wellbore stability.
                    These benefits translate into optimized well performance and
                    extended well life.
                  </li>
                  <li>
                    <strong>Customization and Compatibility:</strong> Our casing
                    accessories are available in various sizes and
                    configurations to accommodate different casing
                    specifications. We offer customization options to meet
                    specific project requirements, ensuring a seamless fit and
                    integration with your well design.
                  </li>
                  <li>
                    <strong>Technical Support:</strong> Our experienced team
                    provides comprehensive technical support throughout the
                    planning, installation, and evaluation phases. We offer
                    guidance on the selection and placement of casing
                    centralizers and stop collars, ensuring their effective
                    utilization and maximizing their benefits.
                  </li>
                </ul>
                <p>
                  Partner with us for your Casing Centralizers and Stop Collar
                  needs. Our commitment to quality, reliability, and technical
                  expertise ensures that you receive top-of-the-line casing
                  accessories that contribute to the overall success of your
                  wellbore operations. Contact us today to learn more about our
                  offerings and discuss how we can assist you.
                </p>

                <div class="feature-imagebox border download-action">
                  <div class="block">
                    <h3>Contact Us Today</h3>
                    <p>Reach out to us let’s discuss your application needs</p>
                    <a href="#" target="_blank" class="btn">
                      Contact Us <i class="fa fa-phone"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Casing;
