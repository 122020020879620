import React from "react";
import Service from "../Components/Service/Service";
import About from "../Components/About/About";
import Content from "../Components/Content/Content";
import Parallax from "../Components/Parallax/Parallax";
import Testimonials from "../Components/Testimonials/Testimonials";
import Clients from "../Components/Clients/Clients";
import Offered from "../Components/Offered/Offered";
import Hero from "../Components/Hero/Hero";
import Work from "../Components/Work/Work";
const Home = () => {
  return (
    <>
      <Hero />
      <Content />
      <About />
      <Parallax />
      <Work />
      <Service />
      {/* <Testimonials /> */}
      <Offered />
      {/* <Clients /> */}
    </>
  );
};

export default Home;
