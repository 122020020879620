import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonials = () => {
  return (
    <>
      <div class="section section-quotes pb-1 bg-light">
        <div class="container">
          <div class="content row">
            <div class="col-md-offset-2 col-md-8 center">
              <h2 class="heading-section">What Our Client Say's</h2>
            </div>
            <div class="gaps"></div>
            <div class="testimonials">
              <div
                id="testimonial"
                class="quotes-slider col-md-8 col-md-offset-2"
              >
                <Swiper
                  modules={[Pagination, A11y, Autoplay]}
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <div class="item">
                      <div class="quotes">
                        <div class="quotes-text center">
                          <p>
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo nemo enim ipsam.
                          </p>
                        </div>
                        <div class="profile">
                          <h5>John Doe</h5>
                          <h6>CEO, Company Name</h6>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="item">
                      <div class="quotes">
                        <div class="quotes-text center">
                          <p>
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo nemo enim ipsam.
                          </p>
                        </div>
                        <div class="profile">
                          <h5>John Doe</h5>
                          <h6>CEO, Company Name</h6>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
