import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Resend } from "resend";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  function onChange(value) {
    localStorage.setItem("Captcha value:", value);
  }
  const resend = new Resend('re_cLAedZCS_KVNC6otodMy4tywz1TuAUC3x');


  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const data = await resend.emails.send({
        to: "uwadiegwu.emmanuel48@gmail.com",
        from: "noreply@example.com",
        subject: `New contact form submission from ${name}`,
        text: `
        Name: ${name}
        Email: ${email}
        Message: ${message}
      `,
      });

      console.log(data);
    } catch (error) {
      console.error(error);
    }

    // const data = await resend.emails.send({});

    setIsSubmitting(false);
    setIsSubmitted(true);
  };

  return (
    <>
      <div class="section section-contents section-freequote section-pad">
        <div class="container">
          <div class="row">
            <div class="freequote-content row">
              {/* <div class="quote-list col-md-8 res-m-bttm">
                <div class="quote-group">
                  <h1>Contact us.</h1>
                  <p>
                    Welcome to our Customer Service! We are here to provide you
                    with exceptional support and assistance.
                  </p>
                  <form class="form-quote" onSubmit={onSubmit}>
                    <div class="form-group row">
                      <div class="form-field col-md-12 form-m-bttm">
                        <p>Name</p>
                        <input
                          name="name"
                          type="text"
                          placeholder="FullName *"
                          className="form-control required"
                          value={name}
                          onChange={(e) => setName(e.target.value)} // Updated handler
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="form-field col-md-6 form-m-bttm">
                        <p>Email</p>
                        <input
                          name="email"
                          type="email"
                          placeholder="Email *"
                          className="form-control required email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} // Updated handler
                        />
                      </div>
                      <div class="form-field col-md-6">
                        <p>Phone Number</p>
                        <input
                          name="quote-request-phone"
                          type="text"
                          placeholder="Phone Number *"
                          class="form-control required"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="form-field col-md-6">
                        <p>Best Time to Reach</p>
                        <select name="quote-request-reach">
                          <option value="">Please select</option>
                          <option value="09am-12pm">09 AM - 12 PM</option>
                          <option value="12pm-03pm">12 PM - 03 PM</option>
                          <option value="03pm-06pm">03 PM - 06 PM</option>
                        </select>
                      </div>
                      <div class="form-field col-md-6">
                        <p>Hear About Us</p>
                        <select name="quote-request-hear">
                          <option value="">Please select</option>
                          <option value="Friends">Friends</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Google">Google</option>
                          <option value="Collegue">Collegue</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="form-field col-md-12">
                        <p>Your Message</p>
                        <textarea
                          name="message"
                          placeholder="Message *"
                          className="txtarea form-control required"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)} // Updated handler
                        ></textarea>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="hidden"
                      name="form-anti-honeypot"
                      value=""
                    />
                    <div class="g-recaptcha">
                      <ReCAPTCHA
                        sitekey="6LeLlCgmAAAAABgGm0bZU-beA4Zy0CKUaiabOLxD"
                        onChange={onChange}
                      />
                    </div>
                    <div class="gaps"></div>
                    <button type="submit" class="btn">
                      Submit
                    </button>
                    {isSubmitting && <div>Loading...</div>}
                    {isSubmitted && <div>Done!</div>}
                    <div class="form-results"></div>
                  </form>
                </div>
              </div> */}

              <div class="contact-details col-md-12">
                <h3>Contact Information</h3>
                <ul class="contact-list">
                  <li>
                    <em class="fa fa-map" aria-hidden="true"></em>
                    <span>Lagos Address:</span>
                    <span>
                      Plot 3502 Mojisola Kazeem Street, Amuwo Odofin GRA Divine
                      Estate, Lagos, Nigeria.
                    </span>
                  </li>
                  <li>
                    <em class="fa fa-map" aria-hidden="true"></em>
                    <span>Port Harcourt Address:</span>
                    <span>
                      76 B Trans Amadi Industrial Estate, Behind Sky Bank by
                      Danjuma Road junction, Port Harcourt.
                    </span>
                  </li>
                  <li>
                    <em class="fa fa-phone" aria-hidden="true"></em>
                    <span>
                      Telephone : +234 803 313 7395
                      <br />
                      Telephone : +234 809 099 9189
                    </span>
                  </li>
                  <li>
                    <em class="fa fa-envelope" aria-hidden="true"></em>
                    <span>
                      Email :{" "}
                      <a href="mailto:info@erglobalresources.com">
                        info@erglobalresources.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
