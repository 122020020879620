import React from "react";
import { Link } from "react-router-dom";

const Parallax = () => {
  return (
    <>
      <div
        class="call-action has-parallax cta-large"
        style={{ "background-image": "url('image/plx-dark-default.jpg')" }}
      >
        <div class="cta-block">
          <div class="container">
            <div class="content row">
              <div class="cta-content wide-sm center">
                <h2>
                  We offer many specialized services in Oil/Gas upstream space
                  in one place. We identify, attract, train and retain
                  indigenous human manpower for knowledge transfer in the Oil
                  and Gas Sector
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="call-action cta-small height">
        <div class="cta-block">
          <div class="container">
            <div class="content row">
              <div class="cta-sameline">
                <p>
                  For specialized services in the oil and gas upstream space, do not hesitate
                  to contact us.
                </p>
                <Link class="btn btn-light" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Parallax;
