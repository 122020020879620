import React from "react";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <>
      <div class="section section-content pt-15 bg-light">
        <div class="container">
          <div class="content row">
          <div class="col-md-offset-2 col-md-8 center">
              <h2 class="heading-section">Our Services</h2>
            </div>
            <div class="gaps"></div>
            <div class="feature-row feature-service-row row feature-s5">
              <div class="col-sm-4 col-xs-12 even">
                <div class="feature boxed">
                  <Link to="/partners">
                    <div class="fbox-photo">
                      <img src="image/partners.png" alt="" />
                    </div>
                    <div class="fbox-over">
                      <h3 class="title">Technical Partnership</h3>
                      <div class="fbox-content">
                        <p>
                          We partner with reputable international companies, for
                          knowledge transfer in the Oil Gas upstream sector, Our
                          Technical Partners are manufacturers of Drill Bits,
                          Cementing and Casing Accessories.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-sm-4 col-xs-12 odd">
                <div class="feature boxed">
                  <Link to="/products-and-services">
                    <div class="fbox-photo">
                      <img src="image/consult.jpg" alt="" />
                    </div>
                    <div class="fbox-over">
                      <h3 class="title">Consultancy Services</h3>
                      <div class="fbox-content">
                        <p>
                          We have a crew of experts that are readily available
                          to proffer solutions, your drilling needs ranging from
                          Directional Drilling, Mud Engineering and Drilling
                          Optimization.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-sm-4 col-xs-12 even">
                <div class="feature boxed">
                  <Link to="/products-and-services">
                    <div class="fbox-photo">
                      <img src="./image/cement2.png" class="h10" alt="" />
                    </div>
                    <div class="fbox-over">
                      <h3 class="title">
                        Tools & Equipment Sales and Installations
                      </h3>
                      <div class="fbox-content">
                        <p>
                          From Bridge Plugs to Cement Retainers, Packers, Snap
                          Latch and Mechanical Setting tools.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
