import React from "react";
import Work from "../Components/Work/Work";

const AboutPage = () => {
  return (
    <>
      <div class="section section-contents section-pad">
        <div class="container">
          <div class="content row">
            <div class="row">
              <div class="col-md-8">
                <h1>About Our Company</h1>
                <p>
                  E&R Global Resources Ltd is a young and dynamic Oil and Gas
                  Service company with interest in the Upstream Sector of the
                  industry. The focus of its set up was borne out of the need to
                  form strategic international partnerships to offer many
                  specialized services in Oil/Gas upstream space in one place.
                  We identify, attract, train and retain indigenous human
                  manpower for knowledge transfer in the Oil and Gas Sector.
                </p>
                <p>
                  We are a one stop center for Drill Bits sales & PDC Bits
                  repair, Fishing Operations and Whipstock Services, Cementing &
                  Liner Hanger Services, OCTG/Pipes, Drilling & Production
                  Chemicals, Consultancy Services & Materials Supply.
                </p>

                <div class="clear"></div>
                <hr />

                <h1>Organisational Announcement</h1>
                <p>
                  We are pleased to announce Iyke Osuji as the Chairman and CEO
                  of E&R Global Resources Ltd. Iyke Osuji is a seasoned
                  architect and a successful businessman. He brings to E&R
                  Global Resources Ltd many years of cognate experience in
                  business management with specialty in growing start-ups to
                  multi-million dollar companies.
                </p>
                <p>
                  We wish Iyke Osuji an exceptional growth and exploits as he
                  steers the affairs of our company.
                </p>

                <div>
                  <img src="image/ceo.jpeg" class="w-10" alt="" />
                </div>
                <h2 style={{"color": "black"}}>CEO: Iyke Osuji</h2>

                <div class="clear"></div>
                <hr />

                <h2>Our Strategic Purpose</h2>
                <p class="lead">
                  Our purpose follow leading design, engineering and industrial
                  manufacturing company specialising in mining, oil, gas and
                  engineering services.
                </p>
                <div class="gaps"></div>
                <h4>Our Vision</h4>
                <p>
                  Our Vision is to be the best indigenous Service provider in
                  the upstream sector for downhole tools and consulting
                  services.
                </p>
                <h4>Our Mission</h4>
                <p>
                  Our Mission is leveraging on strategic alliance in delivering
                  best in class solutions to our teeming customers .
                </p>
                <div class="gaps"></div>
                <div class="col-md-7 npl res-m-bttm">
                  <h4>Our Core Values</h4>
                  <p>
                    Strategic Partnerships, Integrity, Customer Satisfaction,
                    Accountability & Excellence
                  </p>
                </div>

                <div class="clear"></div>

                <hr />
                <Work />
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
