import React from "react";

const Content = () => {
  return (
    <>
      <div class="bg-light pt-15">
        <div class="container">
          <div class="content row">
            <div class="wide-sm center">
              <h1>Welcome</h1>
              <p>
                We are a one stop center for Drill Bits sales & PDC Bits repair,
                Fishing Operations and Whipstock Services, Cementing & Liner
                Hanger Services, OCTG/Pipes, Drilling & Production Chemicals,
                Consultancy Services & Materials Supply
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
