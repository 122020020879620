import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 50);
      console.log(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  });
  return (
    <>
      <header class="site-header header-s1">
        <div class="topbar">
          <div class="container">
            <div class="row">
              <div class="top-aside top-left">
                <ul class="top-nav">
                  {/* <li>
                    <Link to="careers.html">Career</Link>
                  </li>
                  <li>
                    <Link to="news.html">News</Link>
                  </li> */}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div class="top-aside top-right clearfix">
                <ul class="top-contact clearfix">
                  <li class="t-email t-email1">
                    <em class="fa fa-envelope-o" aria-hidden="true"></em>
                    <span>
                      <Link to="#">info@erglobalresources.com</Link>
                    </span>
                  </li>
                  <li class="t-phone t-phone1">
                    <em class="fa fa-phone" aria-hidden="true"></em>
                    <span>+234 803 313 7395</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            sticky ? "sticky navbar navbar-primary" : "navbar navbar-primary"
          }`}
        >
          <div class="container">
            <Link class="navbar-brand" to="index-2.html">
              <img
                class="logo logo-dark"
                alt=""
                src="image/logo.png"
                srcset="image/logo2x.png 2x"
              />
              <img
                class="logo logo-light"
                alt=""
                src="image/logo-light.png"
                srcset="image/logo-light2x.png 2x"
              />
            </Link>
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#mainnav"
                aria-expanded="false"
              >
                <span class="sr-only">Menu</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <div class="quote-btn">
                <Link class="btn" to="get-a-quote.html">
                  Enquire Today
                </Link>
              </div>
            </div>
            <nav class="navbar-collapse collapse" id="mainnav">
              <ul class="nav navbar-nav">
                <li class="dropdown megamenu megamenu-short">
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/about">Our Company</Link>
                </li>

                <li>
                  <Link to="/products-and-services">Products and Services</Link>
                </li>
                {/* <li>
                  <Link to="/partners">Technical Partners</Link>
                </li> */}

                <li>
                  <Link to="/contact">Enquire Today</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
