import React from "react";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <>
      <div class="section section-services section-pad">
        <div class="container">
          <div class="content row">
            <div class="col-md-offset-2 col-md-8 center">
              <h2 class="heading-section">Our Products</h2>
            </div>
            <div class="gaps"></div>
            <div class="feature-row feature-service-row row">
              <div class="col-md-4 col-sm-6 even first">
                <div class="feature boxed">
                  <Link to="/drill-bits-drilling-optimization-tools-and-reamers">
                    <div class="fbox-photo">
                      <img src="./image/drillbits.png" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/drill-bits-drilling-optimization-tools-and-reamers">
                        Drill Bits, Drilling Optimization Tools & Reamers
                      </Link>
                    </h3>
                    <p>
                      We Offer Drill Bits, Drilling Optimization Tools 
                    </p>
                    <p>
                      <Link
                        to="/drill-bits-drilling-optimization-tools-and-reamers"
                        class="btn-link"
                      >
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 odd">
                <div class="feature boxed">
                  <Link to="/">
                    <div class="fbox-photo">
                      <img src="./image/prodchem.jpg" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/">Drilling and production chemicals</Link>
                    </h3>
                    <p>We Offer Drilling and production chemicals</p>
                    <p>
                      <Link to="/" class="btn-link">
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 even">
                <div class="feature boxed">
                  <Link to="/fishing-operations-and-whipstock-services">
                    <div class="fbox-photo">
                      <img src="./image/fishingimg.png" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/fishing-operations-and-whipstock-services">
                        Fishing Operations & Whipstock Services
                      </Link>
                    </h3>
                    <p>We Offer Fishing Operations & Whipstock Services</p>
                    <p>
                      <Link
                        to="/fishing-operations-and-whipstock-services"
                        class="btn-link"
                      >
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 odd">
                <div class="feature boxed">
                  <Link to="/casing-cementing-and-liner-hanger-services">
                    <div class="fbox-photo">
                      <img src="./image/cement2.png" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/casing-cementing-and-liner-hanger-services">
                        Casing, Cementing and Liner Hanger Services
                      </Link>
                    </h3>
                    <p>We Offer Casing, Cementing and Liner Hanger </p>
                    <p>
                      <Link
                        to="/casing-cementing-and-liner-hanger-services"
                        class="btn-link"
                      >
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 odd">
                <div class="feature boxed">
                  <Link to="/casing-centralizers-and-stop-collars">
                    <div class="fbox-photo">
                      <img src="./image/collar.png" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/casing-centralizers-and-stop-collars">
                        Casing Centralizers and Stop Collars
                      </Link>
                    </h3>
                    <p>We Offer Casing Centralizers and Stop Collars</p>
                    <p>
                      <Link
                        to="/casing-centralizers-and-stop-collars"
                        class="btn-link"
                      >
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 odd">
                <div class="feature boxed">
                  <Link to="/stage-cementing-tools">
                    <div class="fbox-photo">
                      <img src="./image/stagecement.png" alt="" />
                    </div>
                  </Link>
                  <div class="fbox-content">
                    <h3>
                      <Link to="/stage-cementing-tools">
                        Stage Cementing Tools
                      </Link>
                    </h3>
                    <p>We Offer Stage Cementing Tools</p>
                    <p>
                      <Link to="/stage-cementing-tools" class="btn-link">
                        Learn More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
