import React from "react";

const Float = () => {
  return (
    <>
      <div class="section section-contents section-products single-product section-pad">
        <div class="container">
          <div class="content row">
            <div class="products-details row">
              <div class="col-md-8 res-m-bttm">
                <h1>Float Shoe and Float Collars</h1>
                <p>
                  A float shoe is a specialized casing shoe installed at the
                  bottom of the casing string. It incorporates a one-way valve
                  mechanism that allows cement slurry to flow downward while
                  preventing any backflow of wellbore fluids. The float shoe
                  enables efficient casing running by reducing drag forces and
                  ensuring proper circulation of drilling fluids. It also helps
                  establish a solid foundation for the cement sheath,
                  contributing to zonal isolation and preventing the migration
                  of reservoir fluids.
                </p>
                <h3>Float Collars:</h3>
                <p>
                  Float collars are placed above the float shoe and are designed
                  to complement its functionality. They incorporate a check
                  valve that allows the cement slurry to pass through during the
                  cementing process, while preventing fluid backflow. Float
                  collars provide an additional barrier against contamination
                  and ensure the integrity of the cement job. They play a
                  critical role in achieving a reliable cement sheath and
                  maintaining zonal isolation throughout the life of the well.
                </p>
                <p class="gaps size-xs"></p>
                <p>
                  <img alt="" src="image/floatsho.png" />
                </p>

                <h3>Key Benefits of Our Float Shoe and Float Collars:</h3>
                <ul class="list-style">
                  <li>
                    <strong>Superior Design and Reliability: </strong> Our float
                    shoe and float collars are engineered with precision using
                    high-quality materials. They undergo rigorous testing to
                    ensure exceptional performance and reliability in
                    challenging downhole conditions.
                  </li>
                  <li>
                    <strong>Optimal Cement Placement: </strong> By incorporating
                    a one-way valve mechanism, our float shoe and float collars
                    facilitate efficient cement placement, preventing fluid
                    migration and ensuring zonal isolation. This results in a
                    robust and effective cement sheath that enhances well
                    integrity.
                  </li>
                  <li>
                    <strong>Easy Installation and Compatibility: </strong>Our
                    float shoe and float collars are designed for easy
                    installation, compatibility with various casing sizes, and
                    seamless integration into your casing and cementing
                    operations. This streamlines the overall process and
                    minimizes potential complications.
                  </li>
                  <li>
                    <strong>Stringent Quality Control: </strong>We adhere to
                    stringent quality control processes throughout the
                    manufacturing and inspection phases. Our commitment to
                    quality ensures that our float shoe and float collars meet
                    or exceed industry standards, providing you with reliable
                    and consistent performance.
                  </li>
                  <li>
                    <strong>Technical Support: </strong>Our team of experts is
                    available to provide technical support and guidance during
                    the planning, installation, and evaluation of our float shoe
                    and float collar solutions. We are dedicated to assisting
                    you in selecting the appropriate components and optimizing
                    their performance for your specific well requirements.
                  </li>
                </ul>
                <p>
                  Choose our Float Shoe and Float Collars for dependable and
                  efficient casing running and cementing operations. With our
                  high-quality products and expertise, we ensure that your
                  wellbore is properly sealed, achieving the desired zonal
                  isolation and maximizing the success of your well. Contact us
                  today to learn more about our float shoe and float collar
                  offerings and discuss how we can support your project.
                </p>

                <div class="feature-imagebox border download-action">
                  <div class="block">
                    <h3>Contact Us Today</h3>
                    <p>Reach out to us let’s discuss your application needs</p>
                    <a href="#" target="_blank" class="btn">
                      Contact Us <i class="fa fa-phone"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="sidebar-right">
                  <div class="wgs-box boxed">
                    <h3 class="wgs-heading">Featured Products</h3>
                    <div class="wgs-content">
                      <p>
                        <img src="image/download.jpg" alt="" />
                      </p>
                     
                      
                    </div>
                  </div>

                  <div class="wgs-box wgs-quoteform">
                    <h3 class="wgs-heading">Quick Contact</h3>
                    <div class="wgs-content">
                      <p>
                        If you have any questions or would like to book a
                        session please contact us.
                      </p>
                      <form
                        id="contact-us"
                        class="form-quote"
                        action="http://demo.themenio.com/industrial/form/contact.php"
                        method="post"
                      >
                        <div class="form-results"></div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-name"
                              type="text"
                              placeholder="Name *"
                              class="form-control required"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-email"
                              type="email"
                              placeholder="Email *"
                              class="form-control required email"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field form-m-bttm">
                            <input
                              name="contact-phone"
                              type="text"
                              placeholder="Phone"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <input
                              name="contact-service"
                              type="text"
                              placeholder="Interest of Service"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-field">
                            <textarea
                              name="contact-message"
                              placeholder="Messages *"
                              class="txtarea form-control required"
                            ></textarea>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="hidden"
                          name="form-anti-honeypot"
                          value=""
                        />
                        <button type="submit" class="btn btn-alt sb-h">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Float;
