import React from "react";
import { Pitemdata } from "./Pitemdata";
import { Link } from "react-router-dom";
const ProductsPage = () => {
  return (
    <>
      <div class="section section-contents section-products section-pad">
        <div class="container">
          <div class="content row">
            <div class="wide-md center">
              <h1>OUR SERVICES</h1>
              <p>We Give Best Services For Your Business.</p>
            </div>
            <div class="gallery gallery-products with-caption hover-fade center mgfix">
              <ul class="photos-list col-x3">
                {Pitemdata.slice(0, 3).map((item) => (
                  <li>
                    <div class="photo">
                      <img src={item.img} alt="" />
                      <div class="photo-link">
                        <span class="links">
                          <Link class="btn more-link" to={`${item.link}`}>
                            View Product
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div class="photo-caption">
                      <Link to={`${item.link}`}>
                        <h4>{item.title}</h4>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div class="gallery gallery-products with-caption hover-fade center mgfix">
              <ul class="photos-list col-x3">
                {Pitemdata.slice(3, 6).map((item) => (
                  <li>
                    <div class="photo">
                      <img src={item.img} alt="" />
                      <div class="photo-link">
                        <span class="links">
                          <Link class="btn more-link" to={`${item.link}`}>
                            View Product
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div class="photo-caption">
                      <Link to={`${item.link}`}>
                        <h4>{item.title}</h4>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div class="gallery gallery-products with-caption hover-fade center mgfix">
              <ul class="photos-list col-x3">
                {Pitemdata.slice(6, 9).map((item) => (
                  <li>
                    <div class="photo">
                      <img src={item.img} alt="" />
                      <div class="photo-link">
                        <span class="links">
                          <Link class="btn more-link" to={`${item.link}`}>
                            View Product
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div class="photo-caption">
                      <Link to={`${item.link}`}>
                        <h4>{item.title}</h4>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsPage;
