import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div class="footer-widget section-pad">
        <div class="container">
          <div class="row">
            <div class="widget-row row">
              <div class="footer-col col-md-5 col-sm-6 res-m-bttm">
                <div class="wgs wgs-footer wgs-menu">
                  <h5 class="wgs-title">Products</h5>
                  <div class="wgs-content">
                    <ul class="menu col-md-6 npl">
                      <li>
                        <Link to="/drill-bits-drilling-optimization-tools-and-reamers">
                          Drill Bits, Drilling Optimization Tools & Reamers
                        </Link>
                      </li>
                      <li>
                        <Link to="/fishing-operations-and-whipstock-services">
                          Fishing Operations/Whipstock Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/casing-cementing-and-liner-hanger-services">
                          Cementing and Casing Accessories
                        </Link>
                      </li>
                      <li>
                        <Link to="/casing-centralizers-and-stop-collars">
                          Casing, Cementing and Liner Hanger Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/float-shoe-and-float-collars">
                          Float Shoe and Float Collars
                        </Link>
                      </li>
                      <li>
                        <Link to="/stage-cementing-tools">
                          Stage Cementing Tools
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="footer-col col-md-2 col-sm-6 res-m-bttm">
                <div class="wgs wgs-footer wgs-menu">
                  <h5 class="wgs-title">Quick Links</h5>
                  <div class="wgs-content">
                    <ul class="menu">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">Our Company</Link>
                      </li>
                      <li>
                        <Link to="/products-and-services">
                          Products and services
                        </Link>
                      </li>
                      <li>
                        <Link to="Partners">Technical Partners</Link>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="footer-col col-md-2 col-sm-6 res-m-bttm">
                <div class="wgs wgs-footer wgs-text"></div>
              </div>
              <div class="footer-col col-md-3 col-sm-6">
                <div class="wgs wgs-footer">
                  <h5 class="wgs-title">Contact us</h5>
                  <div class="wgs-content">
                    <p>
                      <strong>E&R Global Resources</strong>
                      <br />
                      Lagos Address: Plot 3502 Mojisola Kazeem Street, Amuwo
                      Odofin GRA Divine Estate, Lagos, Nigeria. <br />
                      <br />
                      Port Harcourt Address: 76 B Trans Amadi Industrial Estate,
                      Behind Sky Bank by Danjuma Road junction, Port Harcourt.
                    </p>
                    <p>
                      <span>Phone</span>: +234 803 313 7395
                      <br />
                      <span>Phone</span>: +234 809 099 9189
                    </p>
                    <ul class="social">
                      <li>
                        <Link to="#">
                          <em class="fa fa-facebook" aria-hidden="true"></em>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <em class="fa fa-twitter" aria-hidden="true"></em>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <em class="fa fa-linkedin" aria-hidden="true"></em>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="container">
          <div class="row">
            <div class="site-copy col-sm-7">
              <p>
                &copy; 2023 E&R Global Resources <span class="sep"> . </span>{" "}
                Licensed NI099999
                <span class="sep"> . </span> <Link to="#">Terms and Condition</Link>
              </p>
            </div>
            <div class="site-by col-sm-5 al-right">
              <p>
                Made by <Link to="https://techmint.africa">Techmint Africa</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
