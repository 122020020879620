export const Pitemdata = [
    {
        id: 1,
        img: './image/drillbits.png',
        title: 'Drill Bits, Drilling Optimization Tools & Reamers',
        link: '/drill-bits-drilling-optimization-tools-and-reamers',
    },
    {
        id: 2,
        img: './image/fishingimg.png',
        title: 'Fishing Operations & Whipstock Services',
        link: '/fishing-operations-and-whipstock-services',
    },
    {
        id: 4,
        img: './image/cement2.png',
        title: 'Casing, Cementing and Liner Hanger Services',
        link: '/casing-cementing-and-liner-hanger-services',
    },
    {
        id: 5,
        img: './image/collar.png',
        title: 'Casing Centralizers and Stop Collars',
        link: '/casing-centralizers-and-stop-collars',
    },
    {
        id: 5,
        img: './image/floatshoe.png',
        title: 'Float Shoe and Float Collars',
        link: '/float-shoe-and-float-collars',
    },
    {
        id: 3,
        img: './image/stagecement.png',
        title: 'Stage Cementing Tools',
        link: '/stage-cementing-tools',
    },
    {
        id: 3,
        img: './image/bridgeplugs.png',
        title: 'Bridge Plugs, Cement Retainers & Packers',
        link: '/bridge-plugs-cement-retainers-and-packers',
    },
    {
        id: 3,
        img: './image/prodchem.jpg',
        title: 'Drilling & Production Chemicals',
    },

    // {
    //     id: 6,
    //     img: './image/float.png',
    //     title: 'Casing Centralizers and Stop Collars',
    // },
]